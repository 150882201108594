"use client";
import { FC } from "react";
import { SearchButton } from "../common";
import { useSettingsContext } from "@/context";
import { useRouter } from "@/navigation";
import SearchCardHeader from "../common/search-cards-header";

const SearchTopSectionHeader: FC<any> = ({ searchTopCards, searchCategory, setSearchBarShow }) => {
  const router = useRouter();
  const { onUpdate } = useSettingsContext();

  const handleRedirect = (searchTerm: string) => {
    onUpdate("searchTerm", searchTerm);
    onUpdate("currentTab", "Supplier");
    router.push("/network-tabs");
    setSearchBarShow(false)
  };
  return (
    <div className="custom-search-css-header">
      <div className="custom-search-cards-header px-md-3">
        <h4 className="fw-light fs-4 mb-2 mt-3">{searchTopCards?.headline}</h4>

        <div className="row py-md-2">
          {searchTopCards?.items?.length>0 &&
            searchTopCards?.items.map((item: any, index: number) => (
              <SearchCardHeader data={item} key={index}
              setSearchBarShow={setSearchBarShow}
              />
            ))}
        </div>

        <h4 className="text-black fs-5 mt-2 px-2">Most Popular searches</h4>
        <ul className="nav bg-white px-2">
          {searchCategory &&
            searchCategory.items.map((item: any, index: any) => (
              <li
                className="nav-item border border-dark border-1 rounded-5 mx-1 mx-md-2 px-md-2 my-2 tabs-hover-effect cursor-pointer"
                key={index}
              >
                <a
                  className="nav-link active"
                  onClick={() => handleRedirect(item.title)}
                >
                  {item.title}
                </a>
              </li>
            ))}
        </ul>

        <SearchButton />
      </div>
    </div>
  );
};

export default SearchTopSectionHeader;
