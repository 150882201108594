"use client";
import React, { FC, useCallback, useState, useEffect } from "react";
import { Link, usePathname } from "@/navigation";
import {
  Button,
  Container,
  Nav,
  Navbar,
  Offcanvas,
} from "@/components/bootstrap";
import { Cart } from "@/components/common";
import {
  BellIcon,
  CartIcon,
  CartMobileIcon,
  HeaderSearchIcon,
  HomeIcon,
  LogoIcon,
  SettingIcon,
  UnionIcon,
} from "@/utils/icons";
import LanguageSwitcher from "../language-switcher";
import { DOMAINNAME, PROTOCOL } from "@/utils/constant";
import { useSettingsContext } from "@/context";
import dynamic from "next/dynamic";
import useHideElements from "@/hooks/use-hide-element";
import Compare from "../common/compare";
import HeaderSkeleton from "../skeleton/header-skeleton";
import { BsSearch, BsXLg } from "react-icons/bs";
import { motion } from "framer-motion";
import HeaderSearchBar from "../advanced-search/header-search-bar";
import { useTranslations } from "next-intl";
import MobileLanguageSwitcher from "../mobile-language-switcher";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import useIpDataAndCurrency from "../auth/useIpData";

const Header: FC<any> = ({
  headerMenusData,
  searchTopCards,
  searchCategory,
  searchDropdown,
  activeLang,
}) => {
  const menusUpdatedData = headerMenusData;
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const { IsLoading } = useIpDataAndCurrency();
  const { onUpdate, cartModal, cartItem, compareModal, themeDirection } =
    useSettingsContext();
  const [loading, setLoading] = useState(false);
  const activeDomain = `${PROTOCOL + DOMAINNAME}`;
  const [cartShow, setCartShow] = useState<boolean>(false);
  const [compareShow, setCompareShow] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [searchBarShow, setSearchBarShow] = useState<boolean>(false);
  const [isSearchBarHide, setIsSearchBarHide] = useState<boolean>(false);
  const pathname = usePathname();
  const shouldHideLinks = useHideElements();
  const Menu = useTranslations("Menu");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [bottomPosition, setBottomPosition] = useState<string>("-50px");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 50) {
        setIsVisible(true);
        setBottomPosition("0px");
      } else {
        setIsVisible(false);
        setBottomPosition("-50px");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    onUpdate("themeDirection", activeLang === "ar" ? "rtl" : "ltr");
    onUpdate("currentLocale", activeLang);
  }, [activeLang]);
  useEffect(() => {
    if (headerMenusData) {
      setLoading(false);
    }
  }, [headerMenusData]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const navbarFrontHeader = document.getElementById("navbar-front-header");

      if (scrollY < 150) {
        if (navbarFrontHeader) {
          navbarFrontHeader.style.position = "fixed";
          navbarFrontHeader.style.top = "0";
          navbarFrontHeader.style.transform = "none";
          navbarFrontHeader.style.top = "0px";
          navbarFrontHeader.style.left = "0px";
          navbarFrontHeader.style.right = "0px";
        }
      } else {
        if (scrollPosition > scrollY) {
          if (navbarFrontHeader) {
            navbarFrontHeader.style.position = "fixed";
            navbarFrontHeader.style.backgroundColor = "#fff";
            navbarFrontHeader.style.zIndex = "9999!important";
            navbarFrontHeader.style.top = "0px";
            navbarFrontHeader.style.left = "0px";
            navbarFrontHeader.style.right = "0px";
            if (window.innerWidth < 992) {
              navbarFrontHeader.style.transform = "none";
            } else {
              navbarFrontHeader.style.transform = "translate3d(0px, 0px, 0px)";
            }
            navbarFrontHeader.style.transition = "all 1.0s ease-in-out 0s";
          }
        } else {
          if (navbarFrontHeader) {
            navbarFrontHeader.style.position = "fixed";
            navbarFrontHeader.style.backgroundColor = "#fff";
            navbarFrontHeader.style.top = "0px";
            navbarFrontHeader.style.left = "0px";
            navbarFrontHeader.style.right = "0px";
            navbarFrontHeader.style.transform = "translate3d(0px, -110px, 0px)";
            navbarFrontHeader.style.transition = "all 1.0s ease-in-out 0s";
          }
        }
      }
      setScrollPosition(scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  useEffect(() => {
    if (cartModal) {
      setCartShow(cartModal);
      onUpdate("cartModal", false);
    }
  }, [cartModal]);

  const handleCartShow = useCallback(() => {
    setCartShow((prevVal) => !prevVal);
  }, []);

  useEffect(() => {
    if (compareModal) {
      setCompareShow(compareModal);
      onUpdate("compareModal", false);
    }
  }, [compareModal]);

  const handleCompareShow = useCallback(() => {
    setCompareShow((prevVal) => !prevVal);
  }, []);

  const loginData =
    menusUpdatedData !== "" &&
    menusUpdatedData?.find(
      (i: any) => i.label === "Login" || i.label === "Sign In"
    );
  const registerData =
    menusUpdatedData !== "" &&
    menusUpdatedData?.find(
      (i: any) => i.label === "Register" || i.label === "Sign Up For Free"
    );

  const handleSearchBar = () => {
    setSearchBarShow(!searchBarShow);
  };
  const handleSearchBarHide = () => {
    setSearchBarShow(false);
  };
  useEffect(() => {
    const hideOnPages = ["/"];
    const useHideElements = () => {
      const shouldHideLinks = hideOnPages.includes(pathname);
      return shouldHideLinks;
    };
    const isHeaderHide = useHideElements();
    setIsSearchBarHide(isHeaderHide);
  });
  return (
    <>
      {!loading ? (
        <header className={`${isMobile ? "nav-container" : "bg-body-tertiary "}`}>
        {  isMobile ? (<Navbar
            id="navbar-front-header"
            expand="lg p-2"
            className={`bg-transparent nav-container navbar-mobile ${
              scrollPosition ? "header-bottom bg-white" : ""
            }`}
            style={{
              // borderBottom: "1px solid #d3d3d3",
              right: "0px !important",
            }}
          >
            {/* {!shouldHide &&  <div className="w-100 d-flex justify-content-end mt-4 mb-3 me-3">
          <BellIcon width={24} height={24} />
        </div>} */}

            <Nav className="w-100 d-flex justify-content-between align-items-center flex-row">
              <Link
                className="navbar-brand  align-items-center "
                href={"/"}
                aria-label="e-Procure"
                rel="noopener noreferrer"
              >
                <LogoIcon />
              </Link>
              {!shouldHideLinks ? (
                <div className="d-flex align-items-center gap-2">
                  {!isSearchBarHide &&
                    (!searchBarShow ? (
                      <Nav.Item
                        className="d-flex align-items-center"
                        onClick={handleSearchBar}
                      >
                        <div className="position-relative px-3 cursor-pointer">
                          <HeaderSearchIcon />
                        </div>
                      </Nav.Item>
                    ) : (
                      <Nav.Item
                        className="d-flex align-items-center"
                        onClick={handleSearchBarHide}
                      >
                        <div className="position-relative px-3 cursor-pointer">
                          <BsXLg size={20} />
                        </div>
                      </Nav.Item>
                    ))}
                  <Link
                    href={`/get-started`}
                    className="secondary_btn rounded-5 text-white fw-semibold px-3 py-2"
                  >
                    Sign Up <span className="bx-mobile">For Free</span>
                  </Link>
                </div>
              ) : (
                // <BellIcon width={28} height={28} />
                <Nav.Item className="me-4">
                  <LanguageSwitcher isMobile={true} activeLang={activeLang} />
                </Nav.Item>
              )}
            </Nav>
            {searchBarShow && (
              <motion.div
                className="position-absolute searchbar-form shadow"
                style={{ zIndex: "1" }}
                initial={{ opacity: 1, y: -20, top: "0rem", left: "0" }}
                animate={{ opacity: 1, y: 0, top: "4.5rem", left: "0" }}
                exit={{ opacity: 1, y: -20, top: "0rem", left: "0" }}
                transition={{ duration: 1 }}
              >
                <HeaderSearchBar
                  searchTopCards={searchTopCards}
                  searchCategory={searchCategory}
                  searchDropdown={searchDropdown}
                  isMobile={false}
                  setSearchBarShow={setSearchBarShow}
                />
              </motion.div>
            )}
          </Navbar>) :
          (<Navbar
            id="navbar-front-header"
            expand="lg"
            className={`bg-transparent nav-container navbar-desktop ${
              scrollPosition ? "header-bottom bg-white" : ""
            }`}
          >
            <Container fluid>
              <Link
                className="navbar-brand"
                href="/"
                aria-label="e-Procure"
                rel="noopener noreferrer"
              >
                <LogoIcon />
              </Link>
              <div className="d-flex align-items-center gap-2">
              {!isSearchBarHide &&
                        (!searchBarShow ? (
                          <Nav.Item
                            className="search-icon-show-tablet align-items-center "
                            onClick={handleSearchBar}
                          >
                            <div className="position-relative px-3 cursor-pointer">
                              {/* <BsSearch size={20} /> */}
                              <HeaderSearchIcon />
                            </div>
                          </Nav.Item>
                        ) : (
                          <Nav.Item
                            className="d-flex align-items-center"
                            onClick={handleSearchBarHide}
                          >
                            <div className="position-relative px-3 cursor-pointer">
                              <BsXLg size={20} />
                            </div>
                          </Nav.Item>
                        ))}
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
              </div>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-md`}
                aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                placement="end"
                className={shouldHideLinks && "align-items-end"}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                    eProcure
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {!shouldHideLinks && (
                    <Nav
                      className={`justify-content-between flex-grow-1 pre-auth-container ${
                        shouldHideLinks
                          ? activeLang === "en"
                            ? "border_right"
                            : "border_left"
                          : ""
                      }`}
                    >
                      <div className="pre-auth-items">
                        {menusUpdatedData !== "" &&
                          menusUpdatedData
                            ?.filter(
                              (i: any) =>
                                i.label !== "Sign In" &&
                                i.label !== "Sign Up For Free"
                            )
                            .filter(
                              (data: any) => data.label !== "Logistics Hub"
                            )
                            .map((data: any) => (
                              <Link
                                key={data.id}
                                href={
                                  data.label === "Logistics Hub"
                                    ? "https://alpha.logist-tech.com/en/dashboard"
                                    : `${data.contentRef}`
                                }
                                className={`nav-link position-relative ${
                                  pathname === data.contentRef ? "active" : ""
                                }`}
                                rel="noopener noreferrer"
                              >
                                {data.label}
                                {data.showBadge && (
                                  <span className="position-absolute top-0 badge-position translate-middle-y badge rounded-pill bg-primary">
                                    New
                                  </span>
                                )}
                              </Link>
                            ))}
                      </div>
                      {!isSearchBarHide &&
                        (!searchBarShow ? (
                          <Nav.Item
                            className="search-icon-show-desktop align-items-center "
                            onClick={handleSearchBar}
                          >
                            <div className="position-relative px-3 cursor-pointer">
                              {/* <BsSearch size={20} /> */}
                              <HeaderSearchIcon />
                            </div>
                          </Nav.Item>
                        ) : (
                          <Nav.Item
                            className="d-flex align-items-center"
                            onClick={handleSearchBarHide}
                          >
                            <div className="position-relative px-3 cursor-pointer">
                              <BsXLg size={20} />
                            </div>
                          </Nav.Item>
                        ))}
                    </Nav>
                  )}

                  <Nav
                    className={`justify-content-end flex-grow-2 auth-items ${!isSearchBarHide ? activeLang === "en" ? "ps-3" : "pe-3" : ""}`}
                  >
                    <Nav.Item>
                      <LanguageSwitcher activeLang={activeLang} />
                    </Nav.Item>
                    <Nav.Item className="d-flex align-items-center gap-2 margin-y-2">
                      {!shouldHideLinks && (
                        <>
                          <Link href="/sign-in" className="header-sign-in-btn">
                            {Menu("login")}
                          </Link>
                          <Link
                            href="/get-started"
                            className="header-sign-up-btn"
                          >
                            {Menu("free-signup")}
                          </Link>
                        </>
                      )}
                    </Nav.Item>
                    {/* <Nav.Link href="#action3" aria-label="Notifications">
                  <BellIcon width={22.606} height={25.027} />
                </Nav.Link> */}
                    {!shouldHideLinks && (
                      <Nav.Link
                        onClick={handleCartShow}
                        className={`${themeDirection === "rtl" && "me-2"}`}
                        aria-label="Carts"
                      >
                        <div className="position-relative">
                          <CartIcon />
                          {cartItem && cartItem?.length > 0 && (
                            <span className="badge-count">
                              {cartItem?.length}
                            </span>
                          )}
                        </div>
                      </Nav.Link>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
              {/* <div className="language_show mb-3">
          <LanguageSwitcher activeLang={activeLang}/>
          </div> */}
            </Container>
            {searchBarShow && (
              <motion.div
                className="position-absolute searchbar-form shadow"
                style={{ zIndex: "-1" }}
                initial={{ opacity: 1, y: -20, top: "0rem" }}
                animate={{ opacity: 1, y: 0, top: "4.5rem" }}
                exit={{ opacity: 1, y: -20, top: "0rem" }}
                transition={{ duration: 1 }}
              >
                <HeaderSearchBar
                  searchTopCards={searchTopCards}
                  searchCategory={searchCategory}
                  searchDropdown={searchDropdown}
                  isMobile={false}
                  setSearchBarShow={setSearchBarShow}
                />
              </motion.div>
            )}
          </Navbar>)
          }
          {!shouldHideLinks && (
            <Navbar
              className="sticky-footer"
              style={{
                position: "fixed",
                bottom: bottomPosition,
                transition: "bottom 0.5s, opacity 0.5s",
                opacity: isVisible ? 1 : 0,
              }}
            >
              <Nav className="d-flex w-100 justify-content-between align-items-center px-4">
                <Nav.Item className="icon-item">
                  {/* <ElogoIcon /> */}
                  <MobileLanguageSwitcher activeLang={activeLang} />
                </Nav.Item>
                <Nav.Item className="icon-item">
                  <Link
                    href={`/marketplace`}
                    aria-label="e-Procure"
                    rel="noopener noreferrer"
                  >
                    <HomeIcon />
                  </Link>
                </Nav.Item>
                <Nav.Item className="icon-item">
                  <Link
                    href={`/network-tabs`}
                    aria-label="e-Procure"
                    rel="noopener noreferrer"
                  >
                    <UnionIcon />
                  </Link>
                </Nav.Item>
                <Nav.Item className="icon-item">
                  <div className="cursor-pointer" onClick={handleCartShow}>
                    <div className="position-relative">
                      <CartMobileIcon />
                      {cartItem && cartItem?.length > 0 && (
                        <span className="badge-count">{cartItem?.length}</span>
                      )}
                    </div>
                  </div>
                </Nav.Item>
                <Nav.Item className="icon-item">
                  <Link
                    href={`/sign-in`}
                    aria-label="e-Procure"
                    rel="noopener noreferrer"
                  >
                    <SettingIcon />
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar>
          )}
          <Cart
            showCart={cartShow}
            handleShow={handleCartShow}
            activeLang={activeLang}
          />
          <Compare
            showCart={compareShow}
            handleShow={handleCompareShow}
            activeLang={activeLang}
          />
        </header>
      ) : (
        <HeaderSkeleton
          scrollPosition={scrollPosition}
          shouldHideLinks={shouldHideLinks}
        />
      )}
    </>
  );
};

export default dynamic(() => Promise.resolve(Header), { ssr: false });
