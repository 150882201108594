"use client";
import React, { useState, useEffect, useRef } from "react";
import { InputGroup, Form } from "@/components/bootstrap";
import { HeaderSearchIcon, MicIcon } from "@/utils/icons";
import { fetchElasticSearch } from "@/utils/apiServices";
import { useSettingsContext } from "@/context";
import { SearchDropdownItem, SearchSectionProps } from "../../utils/types";
import { useRouter } from "@/navigation";
import SearchSectionHeader from "./search-section-header";
import { BsSearch, BsXLg } from "react-icons/bs";
import SearchTopSectionHeader from "./search-top-section-header";
import { useTranslations } from "next-intl";

interface SearchFormProps {
  searchTopCards: SearchSectionProps;
  searchCategory: SearchSectionProps;
  isMobile: boolean;
  searchDropdown?: SearchDropdownItem[];
}
interface SecondComponentProps {
  setSearchBarShow: React.Dispatch<React.SetStateAction<boolean>>;
}

type HeaderSearchBarProps = SearchFormProps & SecondComponentProps;
function HeaderSearchBar({
  searchTopCards,
  searchCategory,
  isMobile = false,
  searchDropdown = [],
  setSearchBarShow,
}: HeaderSearchBarProps) {
  const router = useRouter();
  const [searchBoolHeader, setSearchBoolHeader] = useState<boolean>(false);
  const [searchValueHeader, setSearchValueHeader] = useState<string>("");
  const [elasticSearchRes, setElasticSearchRes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [listening, setListening] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(
    searchDropdown[0]?.title || "Marketplace"
  );
  const [searchEnable, setSearchEnable] = useState<boolean>(true);
  const { themeDirection } = useSettingsContext();
  const isRtl = themeDirection === "rtl";
  const searchResultRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchVoiceRef = useRef<HTMLInputElement | null>(null);
  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setSelectedValue(eventKey);
    }
  };
const searchText = useTranslations("searchText")
  useEffect(() => {
    if (typeof window !== "undefined") {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.continuous = false;
      recognition.interimResults = false;

      recognition.onresult = (event: any) => {
        const query = event.results[0][0].transcript;
        setSearchValueHeader(query);
        setListening(false);
      };

      recognition.onerror = (event: any) => {
        console.error(event.error);
        setListening(false);
      };

      const startListening = () => {
        setListening(true);
        recognition.start();
      };

      const stopListening = () => {
        setListening(false);
        recognition.stop();
      };

      window.startListening = startListening;
      window.stopListening = stopListening;
    }
  }, []);

  useEffect(() => {
    const handleClickOutsideHeader = (event: MouseEvent) => {
      if (
        searchResultRef.current &&
        !searchResultRef.current?.contains(event.target as Node) &&
        searchInputRef.current &&
        !searchInputRef.current?.contains(event.target as Node) &&
        searchVoiceRef.current &&
        !searchVoiceRef.current?.contains(event.target as Node)
      ) {
        setSearchBoolHeader(false);
        setSearchValueHeader("");
        setSearchBarShow(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutsideHeader);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideHeader);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (searchValueHeader.length > 0) {
        try {
          setLoading(true);
          const response = await fetchElasticSearch(searchValueHeader);
          setElasticSearchRes(response);
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [searchValueHeader]);

  const handleSearchValueHeader = (e: any) => {
    setSearchValueHeader(e.target.value);
  };

  const handleSearchClickHeader = (e: any) => {
    setSearchBoolHeader(true);
  };
  const handleBtnClick = () => {
    if (searchValueHeader) {
      router.push(`/category-search/${searchValueHeader}`);
      setSearchBarShow(false)
      setSearchValueHeader("")
    }
  };

  return (
    <>
      <InputGroup
        ref={searchInputRef}
        className="border_line"
      >
        <div className="loader-custom"
        style={{visibility: `${loading ? 'visible' : 'hidden'}`}}
        ></div>
        <Form.Control
          aria-label="Search supplier, experts, products etc"
          aria-describedby="basic-addon1"
          placeholder={searchText("search_supplier_experts_products_etc")}
          onClick={handleSearchClickHeader}
          onChange={handleSearchValueHeader}
          className={`form-header-search ms-md-4 ms-0 custom_placeholder ${searchBoolHeader || isMobile ? 'has-border' : 'no-border'} 
          ${isRtl ? 'has-border-rtl' : 'has-border-ltr'}`}
          onFocus={handleSearchClickHeader}
          value={searchValueHeader}
          autoFocus
        />
            <div className="d-flex align-items-center gap-md-4 gap-2 pe-md-8 pe-0 bg-white">
          <div
            ref={searchVoiceRef}
            className={`text-black p-2 voice-btn cursor-pointer ${searchBoolHeader || isMobile ? (isRtl ? 'has-border-right' : 'no-border-right') : ''}`}
            style={
              searchBoolHeader || isMobile
                ? {
                    borderRight: isRtl ? "1px solid #E6E6E6" : "none",
                  }
                : {}
            }
            onClick={() =>
              listening ? window.stopListening() : window.startListening()
            }
          >
            <MicIcon />
          </div>
          {/* <div className="p-2">
        <BsXLg size={20} onClick={()=>setSearchBarShow(false)} className="cursor-pointer"/>
          </div> */}
          <div className="p-2">
            <button disabled={searchEnable} onClick={handleBtnClick} style={{opacity:`${searchEnable ? "0.4" : "1"}`,backgroundColor:"transparent",border:"none",cursor:`${searchEnable ? "not-allowed" : "pointer"}`}}>
         <HeaderSearchIcon/>
            </button>
         </div>
         </div>
      </InputGroup>

        <div
          className="mb-5 bg-body custom-container"
        >

          {!searchValueHeader && (
            <div ref={searchResultRef}>
              {" "}
              <SearchTopSectionHeader
                searchTopCards={searchTopCards}
                searchCategory={searchCategory}
                setSearchBarShow={setSearchBarShow}
              />{" "}
            </div>
          )}

          {searchValueHeader && (
            <div ref={searchResultRef}>
              {" "}
              <SearchSectionHeader
                loading={loading}
                data={elasticSearchRes}
                searchValue={searchValueHeader}
                selectedValue={selectedValue}
                setSearchEnable={setSearchEnable}
                setSearchBarShow={setSearchBarShow}
              />{" "}
            </div>
          )}
        </div>
    </>
  );
}

export default HeaderSearchBar;
