"use client";

import { useState } from "react";
import { Dropdown } from "@/components/bootstrap";
import { useSettingsContext } from "@/context";
import { getDictionary } from "../get-dictionary";
import { usePathname } from "next/navigation";
interface LanguageSwitcherProps {
  isMobile?: boolean;
  activeLang?: "en" | "ar";
}
function LanguageSwitcher({ isMobile,activeLang }: LanguageSwitcherProps) {
  const searchParams = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const locale = activeLang

  const { onUpdate, themeDirection } = useSettingsContext();

  const handleLanguageChange = async (newLanguage: "en" | "ar") => {
    setIsOpen(false);
    const langData = await getDictionary(newLanguage);
    onUpdate("themeLangData", JSON.stringify(langData));
    onUpdate("currentLocale", newLanguage);
    onUpdate("themeDirection", newLanguage === "en" ? "ltr" : "rtl");
    if (locale === "en" && newLanguage === "ar") {
      localStorage.setItem("default_lang_code", "ar");
      const search = searchParams.replace("/en", "/ar");
      window.location.href = search;
    } else if (locale === "ar" && newLanguage === "en") {
      localStorage.setItem("default_lang_code", "en");
      const search = searchParams.replace("/ar", "/en");
      window.location.href = search;
    }
    
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setTimeout(()=>{
    setIsOpen(false);
    },1500)
  };

  return (
    <div className="nav-link lang-container">
      <Dropdown
        show={isOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`${activeLang==="en" ? "left_4" : "right_4"}`}
      >
        <Dropdown.Toggle
          role="button"
          variant=""
          id="dropdown-basic"
          as="span"
          className="cursor-pointer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-expanded={isOpen}
          aria-haspopup="true"
        >
          {themeDirection === "ltr" && activeLang==="en" ? (
            <>
              {isMobile ? (
                <img
                  fetchPriority="high"
                  src="/assets/flag/english-language.svg"
                  alt="Flag"
                  className="rounded-circle"
                  // width={37}
                  // height={37}
                />
              ) : (
                <img
                  src="/assets/flag/english-language.svg"
                  alt="Flag"
                  className="rounded-circle"
                  // width={20}
                  // height={20}
                  fetchPriority="high"
                />
              )}
            </>
          ) : (
            <>
              <img
                loading="lazy"
                src="/assets/flag/arabic-language.svg"
                alt="Saudi Arabia Flag"
                className="rounded-circle"
                // width={20}
                // height={20}
              />{" "}
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={`text-center ${themeDirection === "ltr"? "custom-dropdown-menu" : "custom-dropdown-menu-ar"} ${
            isMobile ? "mob-con" : ""
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {themeDirection === "ltr" && activeLang==="en" ? (
            <Dropdown.Item onClick={() => handleLanguageChange("ar")}>
              <div className="d-flex align-items-center justify-content-start">
                <img
                  loading="lazy"
                  src="/assets/flag/arabic-language.svg"
                  alt="Saudi Arabia Flag"
                  className="rounded-circle"
                  // width={20}
                  // height={20}
                />{" "}
                <p className="px-2 mb-0">العربية</p>
              </div>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleLanguageChange("en")}>
              <div className="d-flex justify-content-end">
              {" "}
                <p className="px-2 mb-0">
                English
                </p>
                <img
                  loading="lazy"
                  src="/assets/flag/english-language.svg"
                  alt="Flag"
                  className="rounded-circle"
                  // width={20}
                  // height={20}
                />
              </div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default LanguageSwitcher;
