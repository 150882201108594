"use client";
import React, { FC, useCallback, useEffect, useState } from "react";
import {Link} from "@/navigation";
import { Button, Nav, Navbar } from "@/components/bootstrap";
import { Cart } from "@/components/common";
import {
  BellIcon,
  CartMobileIcon,
  ElogoIcon,
  HomeIcon,
  LogoIcon,
  SettingIcon,
  UnionIcon,
} from "@/utils/icons";
import LanguageSwitcher from "../language-switcher";
import { DOMAINNAME, PROTOCOL } from "@/utils/constant";
import useHideElements from "@/hooks/use-hide-element";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { useSettingsContext } from "@/context";
import dynamic from "next/dynamic";

const MobileHeader: FC = () => {
  const activeDomain = `${PROTOCOL + DOMAINNAME}`;
  const [cartShow, setCartShow] = useState<boolean>(false);
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [bottomPosition, setBottomPosition] = useState<string>('-50px');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 50) {
        setIsVisible(true);
        setBottomPosition('0px');
      } else {
        setIsVisible(false);
        setBottomPosition('-50px');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleCartShow = useCallback(() => {
    setCartShow((prevVal) => !prevVal);
  }, []);
  const shouldHideLinks = useHideElements();
  const shouldHide = isMobile ? shouldHideLinks : false;
  const { cartItem } = useSettingsContext();
  return (
    <header className="bg-body-tertiary nav-container">
      <Navbar
        expand="lg p-2"
        style={{
          borderBottom: "1px solid #d3d3d3",
          right: "0px !important",
        }}
      >
        {/* {!shouldHide &&  <div className="w-100 d-flex justify-content-end mt-4 mb-3 me-3">
          <BellIcon width={24} height={24} />
        </div>} */}
        <Nav className="w-100 d-flex justify-content-between align-items-center flex-row">

          <Link
            className="navbar-brand  align-items-center "
            href={"/experts-dashboard"}
            aria-label="e-Procure"
            rel="noopener noreferrer"
          >
            <LogoIcon />
          </Link>
          {!shouldHide ? (<Button
              type="button"
              variant="secondary"
              href={`${activeDomain}/get-started`}
              className="rounded-5 text-white fw-semibold px-3 py-2"
              size="sm"
            >
              Sign Up For Free
            </Button>): (
                        <Nav.Item className="me-4">
                        <LanguageSwitcher isMobile={true} />
                      </Nav.Item>
            // <BellIcon width={28} height={28} />
            )}
        </Nav>
      </Navbar>
      {!shouldHide && <Navbar className="sticky-footer"
      style={{
        position: 'fixed',
        bottom: bottomPosition,
        transition: 'bottom 0.5s, opacity 0.5s',
        opacity: isVisible ? 1 : 0,
      }}
      >
        <Nav className="d-flex w-100 justify-content-between align-items-center px-4">
          <Nav.Item className="icon-item">
            <ElogoIcon />
          </Nav.Item>
          <Nav.Item className="icon-item">
            <Link
              href={`${activeDomain}/marketplace`}
              aria-label="e-Procure"
              rel="noopener noreferrer"
            >
              <HomeIcon />
            </Link>
          </Nav.Item>
          <Nav.Item className="icon-item">
            <Link
              href={`${activeDomain}/network-tabs`}
              aria-label="e-Procure"
              rel="noopener noreferrer"
            >
              <UnionIcon />
            </Link>
          </Nav.Item>
          <Nav.Item className="icon-item">
            <Link
              href={activeDomain}
              aria-label="e-Procure"
              rel="noopener noreferrer"
              onClick={handleCartShow}
            >
              <div className="position-relative">
                <CartMobileIcon />
                {cartItem && cartItem?.length>0 && <span className="badge-count">{cartItem?.length}</span>}
                </div>
            </Link>
          </Nav.Item>
          <Nav.Item className="icon-item">
            <Link
              href={`${activeDomain}/sign-in`}
              aria-label="e-Procure"
              rel="noopener noreferrer"
            >
              <SettingIcon />
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar>}
      <Cart activeLang="en" showCart={cartShow} handleShow={handleCartShow} />
    </header>
  );
};

export default dynamic(()=> Promise.resolve(MobileHeader),{ssr:false});
