"use client";
import React from "react";
import { Button } from "@/components/bootstrap";
import { ArrowIcon } from "@/utils/icons";
import { AMAZONE_MEDIA_URL, defaultproduct } from "@/utils/constant";
import { default_supplier_logo, profile_pic } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { useSettingsContext } from "@/context";

const SearchProductsCardHeader = ({ title, content, data, searchValue, key,setSearchBarShow }: any) => {
  const router = useRouter();
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const { onUpdate } = useSettingsContext();

  const handleRedirect = (title: string) => {
    if (title === "Product") {
      router.push(`/category-search/${searchValue}`);
      setSearchBarShow(false)
    } else {
      onUpdate(
        "currentTab",
        title === "Buyer"
          ? "Buyer"
          : title === "Supplier"
          ? "Supplier"
          : title === "Expert"
          ? "Expert"
          : "Supplier"
      );
    onUpdate("searchTerm", searchValue);
      router.push(`network-tabs`);
      setSearchBarShow(false)
    }
  };

  return (
    <div className="row py-sm-3" key={key}>
      <div className={`col-md-3 p-3 ${isMobile ? "elastic-search" : ""}`}>
        <div>
          <h4
            className="fw-normal"
            style={{ fontSize: "0.938rem !important" }}
          >
            {title}
          </h4>
          <p className="fw-light fs-6">{content}</p>
        </div>
        <div
          onClick={() => handleRedirect(title)}
          className="fw-light cursor-pointer"
        >
          View All <ArrowIcon />
        </div>
      </div>
      <div className="col-md-9">
        <div className="d-flex justify-content-start gap-1 gap-md-3">
          {title === "Expert"
            ? data &&
              data
                .slice(0, 4)
                .map((item: any, index: any) => (
                  <img
                    loading="lazy"
                    className="rounded-4 cursor-pointer search-result-img-card"
                    key={index}
                    alt="image"
                    src={
                      item.profile_picture_path
                        ? `${AMAZONE_MEDIA_URL}User/${
                            item.id.split("_")[0]
                          }/Profile/${item.profile_picture_path}`
                        : profile_pic
                    }
                    width={`${isMobile ? 80 : 143}`}
                    height={`${isMobile ? 80 : 147}`}
                    onClick={() => {router.push(`/expert-profile/${item.slug}`)
                    setSearchBarShow(false)
                  }}
                  />
                ))
            : title === "Buyer"
            ? data &&
              data
                .slice(0, 4)
                .map((item: any, index: any) => (
                  <img
                    loading="lazy"
                    className="rounded-4 cursor-pointer search-result-img-card"
                    key={index}
                    alt="image"
                    src={
                      item.business_logo_path
                        ? `${AMAZONE_MEDIA_URL}User/${
                            item.id.split("_")[0]
                          }/BusinessLogo/${item.business_logo_path}`
                        : default_supplier_logo
                    }
                    width={`${isMobile ? 80 : 143}`}
                    height={`${isMobile ? 80 : 147}`}
                    onClick={() => {router.push(`/buyer-profile/${item.slug}`)
                    setSearchBarShow(false)
                  }}
                  />
                ))
            : title === "Product"
            ? data &&
              data
                .slice(0, 4)
                .map((item: any, index: any) => (
                  <img
                    loading="lazy"
                    className="rounded-4 cursor-pointer search-result-img-card"
                    key={index}
                    alt="image"
                    src={
                      item.media_path
                        ? `${AMAZONE_MEDIA_URL}User/${item.seller_id}/Product/${item.id}/${item.media_path}`
                        : defaultproduct
                    }
                    width={`${isMobile ? 80 : 143}`}
                    height={`${isMobile ? 80 : 147}`}
                    onClick={() => {router.replace(`/item-detail/${item.slug}`)
                    setSearchBarShow(false)
                  }}
                  />
                ))
            : data &&
              data
                .slice(0, 4)
                .map((item: any, index: any) => (
                  <img
                    loading="lazy"
                    className="rounded-4 cursor-pointer"
                    key={index}
                    alt="image"
                    src={
                      item.business_logo_path
                        ? `${AMAZONE_MEDIA_URL}User/${
                            item.id.split("_")[0]
                          }/BusinessLogo/${item.business_logo_path}`
                        : default_supplier_logo
                    }
                    width={`${isMobile ? 80 : 143}`}
                    height={`${isMobile ? 80 : 147}`}
                    onClick={() =>
                      {router.push(`/supplier-profile/${item.slug}`)
                      setSearchBarShow(false)}
                    }
                  />
                ))}
        </div>
      </div>
    </div>
  );
};

export default SearchProductsCardHeader;
