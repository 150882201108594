"use client";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
} from "@/components/bootstrap";
import Skeleton from "react-loading-skeleton";
const HeaderSkeleton = ({scrollPosition,shouldHideLinks}:any) => {
  return (
    <header className="bg-body-tertiary nav-container">
    <Navbar
      id="navbar-front-header"
      expand="lg"
      className={`bg-transparent nav-container ${
        scrollPosition ? "header-bottom bg-white" : ""
      }`}
    >
      <Container fluid>
     <Skeleton height={50} width={80}/>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          className={shouldHideLinks && 'align-items-end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              eProcure
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          
          
           <Nav className={`justify-content-start gap-5 ms-5 flex-grow-1 pre-auth-container`}>
                    {Array.from({ length: 5 }, (_, index) => (
                      <div key={index} className="rounded-5 py-4">
                    <Skeleton height={20} width={60}/>
                    </div>
              ))}
            </Nav>
            
            

            <Nav className="justify-content-end align-items-center gap-2 flex-grow-2 ms-4 auth-items">
                <Skeleton height={30} width={30} borderRadius={50}/>
                <Skeleton height={30} width={60} borderRadius={50}/>
                <Skeleton height={30} width={60} borderRadius={50}/>
                <Skeleton height={30} width={30} borderRadius={50}/>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </header>
  )
}

export default HeaderSkeleton