"use client";
import { Offcanvas, Button } from "@/components/bootstrap";
import { useSettingsContext } from "@/context";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { CartProps } from "@/utils/types";
import Lottie from "lottie-react";
import { Link, useRouter } from "@/navigation";
import animationData from "../../utils/animationJson/empty-cart.json";
import { FaLongArrowAltRight } from "react-icons/fa";
import StarRatingsFeature from "./star-ratings-feature";
import { useTranslations } from "next-intl";

function Compare({ showCart, handleShow,activeLang }: CartProps) {
  const router = useRouter();
  const { onUpdate, compareItem,currentCountryCurrency,liveCurrencyRate } = useSettingsContext();
  const compareText = useTranslations("compareText")
  const handleRedirect = () => {
    router.push("/compare");
    handleShow();
  };
  const getAdjustedPrice = (price: number) =>
    liveCurrencyRate ? (price * liveCurrencyRate).toFixed(2) : price;
  return (
    <Offcanvas
      show={showCart}
      onHide={handleShow}
      placement="end"
      className="cart-offcanvas"
    >
      <Offcanvas.Header closeButton className="pb-0 justify-content-between">
        <Offcanvas.Title as={"h6"} className="fs-6">
          {compareText("compare_products")}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 cart-body">
        {compareItem?.length>0 ? (
          <>
            <div className="cart-body-head px-3 py-1">
              <p className="cart-items mb-3">
                {compareItem?.length === 1
                  ? compareText("add_at_least_2_products_for_compare")
                  : compareText("you_can_compare_2_3_products")}
              </p>
            </div>
            {compareItem &&
              compareItem?.slice(0, 4).map((item: any, index: number) => (
                <div key={index} className="p-2">
                    <Link
                  href={`/item-detail/${item?.slug}`}
                  className="py-2 d-flex gap-4 align-items-start text-black"
                  >
                    <img
                      loading="lazy"
                      alt={item?.title}
                      className="border shadow-less p-2 rounded-4 cursor-pointer"
                      src={
                        item?.media_path
                          ? AMAZONE_MEDIA_URL +
                            "User/" +
                            item?.seller_id +
                            "/Product/" +
                            item?.id +
                            "/" +
                            ThumbImageRatio +
                            "/" +
                            item?.media_path
                          : defaultproduct
                      }
                      width={80}
                      height={80}
                    />
                    <div style={{ minWidth: "220px" }}>
                    <p className="text-12 mb-0 text-capitalize">
                      {item?.title.length > 60
                        ? item?.title.substring(0, 60) + '...'
                        : item?.title}
                    </p>
                    {/* / */}
                    {/* <span className="fs-7 fw-light">
                      {item?.inventory_type
                        ? item?.inventory_type
                        : "Product type"}
                    </span> */}
                    <StarRatingsFeature
                    data={item?.avg_rating}
                    starRatedColor="black"
                    starHoverColor="black"
                    starDimension="17px"
                  />
                  <h4 className="text-20 mt-1">{`${getAdjustedPrice(item.price)} ${currentCountryCurrency}`}</h4>
                  </div>
                  </Link>
                </div>
              ))}
            {compareItem?.length > 1 && (
              <div className="px-3">
                <h6 className="check-comparison">{compareText("check_comparison")}</h6>
                <Button
                  onClick={handleRedirect}
                  variant="link"
                  className="cart-compare-btn"
                >
                  <FaLongArrowAltRight size={18} />
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="d-flex flex-column justify-content-between align-items-center">
            <p className="ps-3 py-2 fs-7">{compareText("no_more_products_for_compare")}</p>
            <Lottie animationData={animationData} className="w-75 h-75" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Compare;
