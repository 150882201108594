"use client";
import { FC } from "react";
import { SearchButton } from "../common";
import { SearchApiProps } from "@/utils/types";
import SearchProductsCardHeader from "../common/search-product-card-header.";

type SearchSectionProps = {
  data: SearchApiProps;
  searchValue: string;
  loading: boolean;
  selectedValue?: string;
  setSearchEnable?: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBarShow?: React.Dispatch<React.SetStateAction<boolean>>
};

const SearchSectionHeader: FC<SearchSectionProps> = ({
  data,
  searchValue,
  loading,
  selectedValue,
  setSearchEnable,
  setSearchBarShow
}) => {
  const excludeKeys = [
    "buyer_count",
    "expert_count",
    "product_count",
    "supplier_count",
  ];

  const getFilteredData = () => {
    const marketplaceData = Object.entries(data).filter(([key]) => key === "product");
    const networkData = Object.entries(data).filter(([key]) =>
      ["supplier", "buyer", "expert"].includes(key)
    );
    return [...marketplaceData, ...networkData];
  };

  const filteredData = getFilteredData();
  if(setSearchEnable && filteredData?.length === 0){
    setSearchEnable(true)
  }else if(setSearchEnable){
    setSearchEnable(false)
  }
  return (
    <div className="custom-search-css-header">
      <div className="custom-search-cards-header px-4">
        <h4 className="fw-light fs-4 mb-2 mt-3">
          What type of <span className="fw-bold">{searchValue}</span> are you
          looking for ?
        </h4>

        <div>
          {filteredData?.length === 0 && !loading ? (
          <div className="text-center">
          <img src="../assets/img/empty_data.png" width={100} alt="no data found" />
          <h4 className="mt-5 fw-semibold text-center">We couldn't find any matches!</h4>
          <p className="text-center">Please check the spelling or try searching something else</p>
          </div>
          ) : (
            filteredData?.map(([key, value]) => {
              let countItems = 0;
              if (key === "buyer") {
                countItems = data.buyer_count;
              } else if (key === "supplier") {
                countItems = data.supplier_count;
              } else if (key === "expert") {
                countItems = data.expert_count;
              } else if (key === "product") {
                countItems = data.product_count;
              }

              return (
                <SearchProductsCardHeader
                  key={key}
                  title={key.charAt(0).toUpperCase() + key.slice(1)}
                  content={`Over ${countItems}`}
                  data={value}
                  searchValue={searchValue}
                  setSearchBarShow={setSearchBarShow}
                />
              );
            })
          )}
        </div>
        <SearchButton />
      </div>
    </div>
  );
};

export default SearchSectionHeader;
