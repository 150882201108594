"use client"
import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col } from "@/components/bootstrap";
import Image from "next/image";
import {
  TopSoftwareIcon,
  WhatsappIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkedinIcon,
  GithubIcon,
  PinterestIcon,
  FooterChat,
  FooterPhone,
  FooterMessage,
} from "@/utils/icons";
import AppDownloadSection from "../widgets/app-download-section";
import { DOMAINNAME, PROTOCOL } from "@/utils/constant";
import useHideElements from "@/hooks/use-hide-element"
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import FooterSkeleton from "../skeleton/footer-skeleton";
import { FaPinterest } from "react-icons/fa";
import { Link } from "@/navigation";
import { useTranslations } from "next-intl";
import { motion } from 'framer-motion';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
declare global {
  interface Window {
    $crisp: any;
  }
}
const Footer: FC<any> = ({ footerMenusData }) => {
  const footerText = useTranslations("footerText");
  const menusUpdatedData = footerMenusData?.sort(
    (a:any, b:any) => a.order - b.order
  );
  const activeDomain = `${PROTOCOL + DOMAINNAME}`;
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const shouldHideLinks =  useHideElements();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (footerMenusData) {
      setLoading(false);
    }
  }, [footerMenusData]);
  const [isOpen, setIsOpen] = useState<number | null>(null);

  const toggleAccordion = (index:number) => {
    setIsOpen(isOpen=== index ? null : index)
  };
  useEffect(() => {
    window.$crisp = window.$crisp || [];
  }, []);

  const openCrispChat = () => {
    // Open Crisp chatbox on button click
    window.$crisp.push(["do", "chat:open"]);
  };
  return (
    <>
   {!shouldHideLinks &&
    <footer>
    {!loading ? <Container fluid>
        <Row className="px-sm-3 px-1 mx-1">
          <Col md={12} sm={12} sx={12}>
            <Row>
              {menusUpdatedData !== "" &&
                menusUpdatedData?.map((item: any,index:any) => (
                  <Col
                    key={item.id}
                    lg={2}
                    md={4}
                    sm={12}
                    xs={12}
                    className="px-sm-3 px-1"
                  >
                    <>
                   {!isMobile ? (
                    <>
                    <Link 
                    href={`${item.contentRef}`}
                    className="m-heading">{item.label}</Link>
                    <ul className="m-list">
                      {item.sub_menus.map((subItem: any) => (
                        <li key={subItem.id} className="relative footer-nav-link">
                       {subItem.placeholder==="Chat With Us Now" ? (
                        <>
                        {/* {subItem?.placeholder==="Chat With Us Now" ? <FooterChat/>:""} */}
                            <button
                              className="nav-link active-footer-link"
                              style={{ textTransform: "capitalize" }}
                              onClick={openCrispChat}
                            >
                              {subItem.title}
                            </button>
                            </>
                          ) : (
                            <>
                            {/* {subItem?.placeholder==="+966118274184" ? <FooterPhone/> : subItem?.placeholder==="hello@e-procure.net" ? <FooterMessage /> : ""} */}
                            <Link
                              href={`${subItem.contentRef}`}
                              className="nav-link active-footer-link"
                              // style={{ textTransform: "capitalize" }}
                              rel="noopener noreferrer"
                            >
                              {subItem.title}
                            </Link>
                            </>
                          )}
                          {subItem.placeholder==="Jobs" &&
                            <span className="footer-pill">{footerText("hiring")}</span>}
                            {subItem.placeholder==="B2B Marketplace" &&
                            <span className="footer-pill-blue">{footerText("new")}</span>}
                        </li>
                      ))}
                    </ul>
                    </>
                    ): (
                      <>
                      <motion.div
                      className="accordion-header d-flex justify-content-between my-2 align-items-center"
                      onClick={()=>toggleAccordion(index)}
                      initial={false}
                      // animate={{ backgroundColor: isOpen ? "#f0f0f0" : "#fff" }}
                    >
                      <h4 className="text-18 fw-bold">{item.label}</h4>
                      {isOpen === index ? <BsChevronUp  size={20}/> : <BsChevronDown size={20}/> }
                    </motion.div>
                    <motion.div
                      className="accordion-content"
                      initial={false}
                      animate={{ height: isOpen === index ? "auto" : 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ overflow: "hidden" }}
                    >
                      {isOpen === index && <ul className="m-list">
                      {item.sub_menus.map((subItem: any) => (
                        <li key={subItem.id} className="relative footer-nav-link">
                             {subItem.placeholder==="Chat With Us Now" ? (
                        <>
                        {/* {subItem?.placeholder==="Chat With Us Now" ? <FooterChat/>:""} */}
                            <button
                              className="nav-link active-footer-link"
                              style={{ textTransform: "capitalize" }}
                              onClick={openCrispChat}
                            >
                              {subItem.title}
                            </button>
                            </>
                          ):(
                          <Link
                            href={`${subItem.contentRef}`}
                            className="nav-link active-footer-link"
                            style={{ textTransform: "capitalize" }}
                            rel="noopener noreferrer"
                          >
                            {subItem.title}
                          </Link>)}
                          {subItem.placeholder==="Jobs" &&
                            <span className="footer-pill">{footerText("hiring")}</span>}
                            {subItem.placeholder==="B2B Marketplace" &&
                            <span className="footer-pill-blue">{footerText("new")}</span>}
                        </li>
                      ))}
                    </ul>}
                    </motion.div>
                    </>
                    )}
                    </>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2 mt-md-0 mb-4">
          <Col md={8} className=" px-3 px-md-0 ">
            <div className="justify-content-between align-items-center gap-sm-5 gap-0 d-flex  ">
              <div style={{ width: "20%" }}>
                <TopSoftwareIcon />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="/assets/brand/supplychain.webp"
                  alt="supplychain"
                  className="footer-brand"
                />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="/assets/brand/fintech.webp"
                  alt="fintech"
                  className="footer-brand"
                />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="/assets/brand/inspire.webp"
                  alt="inspire"
                  className="footer-brand"
                />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="/assets/brand/leader.webp"
                  alt="fintech"
                  className="footer-brand"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-4 mx-2">
          <Col md={6} sm={12} xs={12} className="mt-2 mt-md-0">
            <AppDownloadSection />
          </Col>
           <Col
            md={6}
            sm={6}
            xs={12}
            className={`px-3 py-2 py-md-0 px-md-0 d-flex justify-content-end align-items-center ${!isMobile && 'mobile-icon'}`}
          >
            <div className="row ">
            <div className="col-12 col-md-12 mt-3 justify-content-sm-start justify-content-center d-flex d-sm-none">
                <h4 className="m-heading">Let’s Get Social</h4>
              </div>
              <div className="col-12 col-md-12 justify-content-sm-end justify-content-center d-sm-flex d-none">

                <ul className="m-list list-inline social-icon-link mb-0">
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://wa.link/e177g8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link whatsapp_icon"
                      aria-label="whatsapp label"
                    >
                      <WhatsappIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://twitter.com/eProcure_net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link twitter_icon"
                      aria-label="twitter label"
                    >
                      <TwitterIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://www.linkedin.com/company/eprocure-net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link linkedin_icon"
                      aria-label="linkedin label"
                    >
                      <LinkedinIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://www.facebook.com/eprocure.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link facebook_icon"
                      aria-label="facebook label"
                    >
                      <FacebookIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://www.instagram.com/e.procure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link instagram_icon"
                      aria-label="instagram label"
                    >
                      <InstagramIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://www.youtube.com/channel/UCPwue-ELYv5G98RcnAbAxGw"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link youtube_icon"
                      aria-label="youtube label"
                    >
                      <YoutubeIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://github.com/e-Procure"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link github_icon"
                      aria-label="youtube label"
                    >
                      <GithubIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item m-2">
                    <Link
                      href="https://www.pinterest.com/e_procure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link youtube_icon"
                      aria-label="youtube label"
                    >
                      <FaPinterest size={25} />
                    </Link>
                  </li>
                </ul>
                </div>
              <div className="col-9 col-md-12 justify-content-sm-end justify-content-center d-flex d-sm-none m-auto">

                <div className="row">
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="https://api.whatsapp.com/send/?phone=%2B16282004333&text&app_absent=0"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link whatsapp_icon"
                      aria-label="whatsapp label"
                    >
                      <WhatsappIcon />
                    </Link>
                      </div>
                  </div>
                  <div className="col-3">
                  <div className="social-mobile-icons m-2">
                  <Link
                      href="https://twitter.com/eProcure_net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link twitter_icon"
                      aria-label="twitter label"
                    >
                      <TwitterIcon />
                    </Link>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="https://www.linkedin.com/company/eprocure-net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link linkedin_icon"
                      aria-label="linkedin label"
                    >
                      <LinkedinIcon />
                    </Link>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="https://www.facebook.com/eprocure.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link facebook_icon"
                      aria-label="facebook label"
                    >
                      <FacebookIcon />
                    </Link>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="https://www.instagram.com/e.procure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link instagram_icon"
                      aria-label="instagram label"
                    >
                      <InstagramIcon />
                    </Link>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="https://www.youtube.com/channel/UCPwue-ELYv5G98RcnAbAxGw"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link youtube_icon"
                      aria-label="youtube label"
                    >
                      <YoutubeIcon />
                    </Link>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link youtube_icon"
                      aria-label="youtube label"
                    >
                      <GithubIcon />
                    </Link>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="social-mobile-icons m-2">
                  <Link
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link youtube_icon"
                      aria-label="youtube label"
                    >
                      <FaPinterest size={25} />
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="container">
        <hr className="px-5"/>
        </div>
        <Row className="py-4 b-box text-sm-center px-sm-5 px-3">
          <Col className="col-md-4 col-lg-4 col-12 text-sm-start text-center">
            {footerText("footer-powered-by")}{" "}
            <Link href="https://www.saudiprocure.com">
              <Image
                src="/assets/img/spsLogo-grey.svg"
                alt="bell"
                width={79}
                height={23}
                rel="noopener noreferrer"
                className="sps-logo"
                loading="lazy"
              />
            </Link>
          </Col>
          <Col className="col-md-4 col-lg-4 col-12 my-sm-0 my-3">
            <p className="copyrights-text">
              © {new Date().getFullYear()} e-Procure. All rights reserved.
            </p>
          </Col>
          <Col className="col-md-4 col-lg-4 col-12 mb-sm-0 mb-10">
            <hr className="b-line" />
            <ul className="b-links">
              <li>
                <Link
                  href={`/terms-and-conditions`}
                  className="nav-link term-link"
                  rel="noopener noreferrer"
                >
              {footerText("terms")}
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  href={`/privacy-policy`}
                  className="nav-link term-link"
                  rel="noopener noreferrer"
                >
                    {footerText("privacy")}
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  href={`/EULA`}
                  className="nav-link term-link"
                  rel="noopener noreferrer"
                >
                  {footerText("footer-eula")}
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  href="/sitemap"
                  className="nav-link term-link"
                  rel="noopener noreferrer"
                >
                  {footerText("site_map")}
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    : (
      <Container>
      <FooterSkeleton />
      </Container>
    )  
    }
    </footer>}
    </>
  );
};

export default Footer;