import { usePathname } from '@/navigation';

const hideOnPages = ["/get-started","/sign-up-buyer","/sign-up-supplier","/signup-expert","/sign-in","/sign-in-by-email","/choose-workspace","/forgot-password","/forgot-workspace"];

const useHideElements = () => {
  const pathname = usePathname();
  const shouldHideLinks = hideOnPages.includes(pathname);
  return shouldHideLinks;
};

export default useHideElements;
