import type { LocaleNew } from "./i18n";

const dictionaries = {
  en: () =>
    import("../public/dictionaries/en.json").then((module) => module.default),
  ar: () =>
    import("../public/dictionaries/ar.json").then((module) => module.default),
};

export const getDictionary = async (locale: LocaleNew) => {
  return dictionaries[locale]?.() ?? dictionaries.en();
};
