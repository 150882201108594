"use client"
import React, { FC } from "react";
import { Container, Row, Col } from "@/components/bootstrap";
import {Link} from "@/navigation";
import Image from "next/image";
import {
  TopSoftwareIcon,
  WhatsappIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkedinIcon,
} from "@/utils/icons";
import { AppDownloadSection } from "../widgets";
import { DOMAINNAME, PROTOCOL } from "@/utils/constant";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import useHideElements from "@/hooks/use-hide-element";

const Footer: FC<any> = ({ footerMenusData }) => {
  const menusUpdatedData = footerMenusData;
  const activeDomain = `${PROTOCOL + DOMAINNAME}`;
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const shouldHideLinks =  useHideElements();
  return (
    <>
    {
      !shouldHideLinks && <footer>
      <Container>
        <Row>
          {menusUpdatedData !== "" &&
            menusUpdatedData?.map((item: any) => (
              <Col key={item.id}>
                <h4 className="m-heading">{item.label}</h4>
                <ul className="m-list">
                  {item.sub_menus.map((subItem: any) => (
                    <li key={subItem.id}>
                      <Link
                        href={`${activeDomain}${subItem.contentRef}`}
                        className="nav-link"
                        rel="noopener noreferrer"
                      >
                        {subItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
            ))}
          <Col>
            <h4 className="m-heading">Let’s Get Social</h4>
            <ul className="m-list list-inline">
              <li className="list-inline-item m-2">
                <Link
                  href="https://api.whatsapp.com/send/?phone=%2B16282004333&text&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <WhatsappIcon />
                </Link>
              </li>
              <li className="list-inline-item m-2">
                <Link
                  href="https://twitter.com/eProcure_net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <TwitterIcon />
                </Link>
              </li>
              <li className="list-inline-item m-2">
                <Link
                  href="https://www.linkedin.com/company/eprocure-net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <LinkedinIcon />
                </Link>
              </li>
              <li className="list-inline-item m-2">
                <Link
                  href="https://www.facebook.com/eprocure.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <FacebookIcon />
                </Link>
              </li>
              <li className="list-inline-item m-2">
                <Link
                  href="https://www.instagram.com/e.procure/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <InstagramIcon />
                </Link>
              </li>
              <li className="list-inline-item m-2">
                <Link
                  href="https://www.youtube.com/channel/UCPwue-ELYv5G98RcnAbAxGw"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  <YoutubeIcon />
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-between mt-2 mt-md-0 mb-4">
          <Col md={7} className=" px-3 px-md-0 ">
            <div className="justify-content-between d-flex  ">
              <div style={{ width: "20%" }}>
                <TopSoftwareIcon />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="../assets/brand/supplychain.webp"
                  alt="supplychain"
                  className="footer-brand"
                />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="../assets/brand/fintech.webp"
                  alt="fintech"
                  className="footer-brand"
                />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="../assets/brand/inspire.webp"
                  alt="inspire"
                  className="footer-brand"
                />
              </div>
              <div style={{ width: "20%" }}>
                <img
                  loading="lazy"
                  src="../assets/brand/leader.webp"
                  alt="fintech"
                  className="footer-brand"
                />
              </div>
            </div>
          </Col>
          <Col md={3} className="mt-2 mt-md-0">
            <h4 className="m-heading nav-link app-link">Get the App</h4>
            <AppDownloadSection />
          </Col>
        </Row>
        <Row className="py-4 b-box">
          <Col>
            Powered By{" "}
            <Link href="https://www.saudiprocure.com" rel="noopener noreferrer">
              <Image
                src="/assets/img/powered_logo.webp"
                alt="bell"
                width={79}
                height={23}
              />
            </Link>
          </Col>
          <Col>
            <p className="copyrights-text">
              © {new Date().getFullYear()} e-Procure. All rights reserved.
            </p>
          </Col>
          <Col className="col-md-4 col-12">
            <hr className="b-line" />
            <ul className="b-links">
              <li>
                <Link
                  href={`${activeDomain}/terms-and-conditions`}
                  className="nav-link"
                  rel="noopener noreferrer"
                >
                  Terms
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  href={`${activeDomain}/privacy-policy`}
                  className="nav-link"
                  rel="noopener noreferrer"
                >
                  Privacy
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  href={`${activeDomain}/EULA`}
                  className="nav-link"
                  rel="noopener noreferrer"
                >
                  EULA
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
    }

    </>
  );
};

export default Footer;
