"use client";
import React from "react";
import { Row, Col } from "@/components/bootstrap";
import {Link} from "@/navigation";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";

const FooterSkeleton = () => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  return (
    <>
      <Row>
        <Col md={9} sm={9} sx={9}>
          <Row>
            {Array.from({ length: 4 }, (_, index) => (
              <Col key={index} md={3} sm={6} xs={6} className="px-3 px-md-0">
                <h4 className="m-heading">
                  <Skeleton height={20} width={180} />
                </h4>
                <ul className="m-list">
                  {Array.from({ length: 5 }, (_, index) => (
                    <li key={index}>
                      <div>
                        <Skeleton height={20} width={80} />
                      </div>
                    </li>
                  ))}
                </ul>
              </Col>
            ))}
          </Row>
        </Col>

        <Col
          md={3}
          sm={3}
          xs={12}
          className={`px-3 py-2 py-md-0 px-md-0 ${!isMobile && "mobile-icon"}`}
        >
          <div className="row ">
            <div className="col-4 col-md-12">
              <h4 className="m-heading">
                <Skeleton height={20} width={180} />
              </h4>
            </div>
            <div className="col-8 col-md-12">
              <ul className="m-list list-inline">
                <li className="list-inline-item m-2">
                  <div>
                    <Skeleton borderRadius={50} height={30} width={30} />
                  </div>
                </li>
                <li className="list-inline-item m-2">
                  <div>
                    <Skeleton borderRadius={50} height={30} width={30} />
                  </div>
                </li>
                <li className="list-inline-item m-2">
                  <div>
                    <Skeleton borderRadius={50} height={30} width={30} />
                  </div>
                </li>
                <li className="list-inline-item m-2">
                  <div>
                    <Skeleton borderRadius={50} height={30} width={30} />
                  </div>
                </li>
                <li className="list-inline-item m-2">
                  <div>
                    <Skeleton borderRadius={50} height={30} width={30} />
                  </div>
                </li>
                <li className="list-inline-item m-2">
                  <div>
                    <Skeleton borderRadius={50} height={30} width={30} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-between mt-2 mt-md-0 mb-4">
        <Col md={7} className=" px-3 px-md-0 ">
          <div className="justify-content-between d-flex  ">
            <div className="width-20">
              <Skeleton height={80} width={120} />
            </div>
            <div className="width-20">
              <Skeleton height={80} width={120} />
            </div>
            <div className="width-20">
              <Skeleton height={80} width={120} />
            </div>
            <div className="width-20">
              <Skeleton height={80} width={120} />
            </div>
            <div className="width-20">
              <Skeleton height={80} width={120} />
            </div>
          </div>
        </Col>
        <Col md={3} className="mt-2 mt-md-0">
          <h4 className="m-heading nav-link app-link">
            <Skeleton height={20} width={120} />
          </h4>
          <Skeleton height={50} width={150} count={2} />
        </Col>
      </Row>

      <Row className="py-4 b-box text-sm-center">
        <Col className="col-md-4">
          <Skeleton height={20} width={100} />
          <Col className="col-md-4">
            <Skeleton height={20} width={100} />
          </Col>
        </Col>
        <Col className="col-md-4 col-12">
          <p className="copyrights-text">
            <Skeleton height={20} width={200} />
          </p>
        </Col>
        <Col className="col-md-4 col-12">
          <hr className="b-line" />
          <ul className="b-links">
            <li>
              <div>
                <Skeleton height={20} width={100} />
              </div>
            </li>
            <li>|</li>
            <li>
              <div>
                <Skeleton height={20} width={100} />
              </div>
            </li>
            <li>|</li>
            <li>
              <div>
                <Skeleton height={20} width={100} />
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default FooterSkeleton;
