"use client";
import React, { FC, useCallback, useState, useEffect } from "react";
import Image from "next/image";
import {Link} from "@/navigation";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Button,
} from "@/components/bootstrap";
import { Cart } from "@/components/common";
import {
  LogoIcon,
} from "@/utils/icons";
import LanguageSwitcher from "../language-switcher";
import { useSettingsContext } from "@/context";
import dynamic from "next/dynamic";
import useHideElements from "@/hooks/use-hide-element";

const Header: FC<any> = ({ headerMenusData }) => {
  const menusUpdatedData = headerMenusData;
  const [cartShow, setCartShow] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const { cartItem } = useSettingsContext();
  const shouldHideLinks = useHideElements();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const navbarFrontHeader = document.getElementById("navbar-front-header");

      if (scrollY < 150) {
        if (navbarFrontHeader) {
          navbarFrontHeader.style.position = "fixed";
          navbarFrontHeader.style.top = "0";
          navbarFrontHeader.style.transform = "none";
          navbarFrontHeader.style.top = "0px";
          navbarFrontHeader.style.left = "0px";
          navbarFrontHeader.style.right = "0px";
        }
      } else {
        if (scrollPosition > scrollY) {
          if (navbarFrontHeader) {
            navbarFrontHeader.style.position = "fixed";
            navbarFrontHeader.style.backgroundColor = "#fff";
            navbarFrontHeader.style.zIndex = "9999";
            navbarFrontHeader.style.top = "0px";
            navbarFrontHeader.style.left = "0px";
            navbarFrontHeader.style.right = "0px";
            if (window.innerWidth < 992) {
              navbarFrontHeader.style.transform = "none";
            } else {
              navbarFrontHeader.style.transform = "translate3d(0px, 0px, 0px)";
            }
            navbarFrontHeader.style.transition = "all 1.0s ease-in-out 0s";
          }
        } else {
          if (navbarFrontHeader) {
            navbarFrontHeader.style.position = "fixed";
            navbarFrontHeader.style.backgroundColor = "#fff";
            navbarFrontHeader.style.top = "0px";
            navbarFrontHeader.style.left = "0px";
            navbarFrontHeader.style.right = "0px";
            navbarFrontHeader.style.transform = "translate3d(0px, -110px, 0px)";
            navbarFrontHeader.style.transition = "all 1.0s ease-in-out 0s";
          }
        }
      }
      setScrollPosition(scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const handleCartShow = useCallback(() => {
    setCartShow((prevVal) => !prevVal);
  }, []);

  const loginData =
    menusUpdatedData !== "" &&
    menusUpdatedData?.find((i: any) => i.label === "Login");
  const registerData =
    menusUpdatedData !== "" &&
    menusUpdatedData?.find((i: any) => i.label === "Register");

  return (
    <header className="bg-body-tertiary nav-container">
      <Navbar
        id="navbar-front-header"
        expand="lg"
        className={`bg-transparent nav-container ${
          scrollPosition ? "header-bottom" : ""
        }`}
      >
        <Container fluid>
          <Link
            className="navbar-brand"
            href="/experts-dashboard"
            aria-label="e-Procure"
            rel="noopener noreferrer"
          >
            <LogoIcon />
          </Link>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                eProcure
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 ms-4 auth-items">
                <Nav.Item>
                  <LanguageSwitcher />
                </Nav.Item>
               {!shouldHideLinks && <Nav.Item className="d-flex align-items-center gap-2">
                  {loginData && registerData && (
                    <>
                      <Link
                        key={loginData.id}
                        href="/sign-in-by-email"
                        className="header-sign-in-btn"
                      >
                        {loginData.label.toUpperCase()}
                      </Link>
                      <Link
                        key={registerData.id}
                        href="/signup-expert"
                        className="header-sign-up-btn"
                      >
                        {registerData.label.toUpperCase()}
                      </Link>
                    </>
                  )}
                </Nav.Item>}
                {/* <Nav.Link href="#action3" rel="noopener noreferrer">
                  <Image
                    src="/assets/icons/bell-icon.svg"
                    alt="bell"
                    width={23}
                    height={25}
                  />
                </Nav.Link> */}
                  {!shouldHideLinks && <Nav.Link onClick={handleCartShow}>
                  <div className="position-relative">
                  <Image
                    src="/assets/icons/cart-icon.svg"
                    alt="bell"
                    width={23}
                    height={25}
                  />
                  {cartItem && cartItem?.length>0 && <span className="badge-count">{cartItem?.length}</span>}
                  </div>
                </Nav.Link>}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Cart activeLang="en" showCart={cartShow} handleShow={handleCartShow} />
    </header>
  );
};

export default dynamic(()=> Promise.resolve(Header),{ssr:false});

