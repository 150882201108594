"use client";
import React, { useState, useEffect } from "react";
import { fetchProductSellerCount } from "@/utils/apiServices";
import { popularSupplierBuyer } from "@/utils/types";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { useSettingsContext } from "@/context";

const SearchCardHeader = ({ data,setSearchBarShow }: any) => {
  const router = useRouter();
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [productSeller, setProductSeller] = useState<popularSupplierBuyer[]>(
    []
  );
  const [count, setCounts] = useState<any>([]);
  function formatCount(count: number) {
    if (count === undefined || isNaN(count)) {
      return "0+";
    }
    const roundedCount = Math.floor(count / 5) * 5;
    return `${roundedCount}+`;
  }
  useEffect(() => {
    async function fetchData() {
      try {
        const popularSuppliers = await fetchProductSellerCount();
        setProductSeller(popularSuppliers.data);
        setCounts(popularSuppliers.counts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const handleData = (slug: string) => {
    router.push(`/supplier-profile/${slug}`);
    setSearchBarShow(false)
  };

  const handleServices = (url: string) => {
    router.push(url);
    setSearchBarShow(false)
  };

  return (
    <div
      className={`col-12 col-md-2 ${isMobile ? "py-2 px-4" : "search-card"}`}
    >
      <div className={`overflow-hidden h-100 shadow-less rounded search-card-detail`}>
        {/* {data?.title === "Suppliers" ? (
          <div
            className={`row {isMobile ? "mobile-seller" : "desktop-seller"}`}
          >
            {productSeller?.map((item: any, index: number) => (
              <div className="col-3 col-md-3 px-md-3" key={index}>
                <img
                  className="rounded-circle bg-white mt-1 cursor-pointer "
                  alt="Suppliers"
                  onClick={() => item?.slug && handleData(item.slug)}
                  src={
                    item.business_logo
                      ? `${AMAZONE_MEDIA_URL}User/${item.supplier_id}/BusinessLogo/${item.business_logo}`
                      : default_supplier_logo
                  }
                  width={30}
                  height={30}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        ) : ( */}
          <img
            loading="lazy"
            alt="Search services"
            src={data.imageUrl}
            width="100%"
            height={74}
            className="cursor-pointer card-img-cover"
            onClick={() => handleServices(data.cta.redirectionUrl)}
          />
        {/* )} */}
        <div
          className="main-heading text-black my-2 fs-6 fw-normal px-2 cursor-pointer"
          onClick={() => handleServices(data.cta.redirectionUrl)}
        >
          {data?.title}
          <br />
          <span className="fw-light fs-7">
            {data?.headline !== null
              ? data.headline
              : data.title === "Products"
              ? formatCount(count?.product_count)
              : formatCount(count.service_count)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchCardHeader;
